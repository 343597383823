<div class="left-side-blog">
  <div class="iq-widget">
    <app-black-card-style7>
      <img cardMedia [src]="image" class="img-fluid mb-3" class="img-fluid mb-3" alt="images">
      <ng-container cardHeader>
        <code>
          <h5>{{ name }}</h5>
        </code>
      </ng-container>
      <ng-container cardBody>
        <p class="mb-3">{{ description }}</p>
      </ng-container>
    </app-black-card-style7>
  </div>
  <div class="iq-widget">
    <h5 class="iq-widget-title text-white mb-4 title">Recent Posts</h5>
    <div class="media" *ngFor="let RecentPosts of recentPosts">
      <img class="mr-3" src="{{ RecentPosts.image }}" alt="image">
      <div class="media-body">
        <a href="javascript:void(0)">
          <h6 class="mt-0 iq-fw-4">{{ RecentPosts.title }}</h6>
        </a>
        <i class="far fa-calendar-alt mr-2"></i>{{ RecentPosts.date }}
      </div>
    </div>
  </div>
  <div class="iq-widget">
    <div class="iq-sidebar-widget ">
      <h5 class="iq-fw-8 mb-4 text-white title">Categories</h5>
      <ul class="d-block" *ngFor="let Categories of categoriesData">
        <li class="d-block mb-3"><a href="javascript:void(0)" class="iq-fw-5">{{ Categories.categoriesName }}<span>{{ Categories.count }}</span></a></li>
      </ul>
    </div>
  </div>
  <div class="iq-widget">
    <div class="iq-sidebar-widget">
      <div class="iq-widget-search position-relative">
        <input type="search" placeholder="Search Your Keyword" class="form-control placeholder search-btn">
        <a href="javascript:void(0)" > <i class="fa fa-search"></i></a>
      </div>
    </div>
  </div>

</div>

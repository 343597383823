<div class="iq-team2 text-center">
  <div class="team-content">
    <ng-content select="[cardMedia]"></ng-content>
  </div>
  <div class="avtar-name iq-mt-20 iq-font-white text-center">
    <ng-content select="[cardTitle]"></ng-content>
  </div>
  <div class="team-social iq-mt-10">
    <ng-content select="[cardFooter]"></ng-content>
  </div>
</div>

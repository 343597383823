<section id="iq-about" class="pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
            </div>
            <div class="row custom-tab verticaltab3 flex-row-reverse">
                <div class="col-lg-6 col-md-12">
                    <ul class="nav nav-pills nav-pills-style-3 mb-5" id="pills-tab" role="tablist" aria-orientation="vertical">
                        <li *ngFor="let list of List"  class="nav-item">
                            <a class="nav-link {{ list.active !== undefined && list.active ? 'active show' : '' }}" data-toggle="pill" (click)="tabActive(list.id)"  href="{{ '#' + list.id }}" role="tab" [attr.aria-selected]=" list.active !== undefined ? true : false ">
                                <div class="media">
                                    <div class="media-body">
                                        <h4 class="mb-2 active-title">{{ list.title }}</h4>
                                        <p class="mb-0">{{ list.description }}</p>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-6 col-md-12  show_content">
                    <div *ngFor="let list of List" id="{{ list.id }}" class="tab-pane fade {{ list.active !== undefined && list.active ? 'active show' : '' }}">
                        <img src="{{ list.image }}" class="img-fluid" alt="img">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--About me-->
<div class="overview-block-ptb grey-bg text-center about-me">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-12">
        <img class="img-fluid" src="./assets/images/logo-inline.png" alt="">
      </div>
      <div class="col-lg-5 col-md-12">
        <div class="text-center iq-mt-20">
          <h6><i>"Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been"</i></h6>
        </div>
      </div>
    </div>
  </div>
</div>
<!--About me end-->

<div class="bg-dark iq-ptb-10 header-top-bar">
  <div class="container-fluid">
    <div class="row">
      <div class="col-auto mr-auto">
        <ng-content select="[leftLinks]"></ng-content>
      </div>
      <div class="col-auto">
        <div class="social-bar">
          <ng-content select="[rightLinks]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="iq-client white-bg">
  <div class="client-img">
    <ng-content select="[cardMedia]"></ng-content>
  </div>
  <div class="client-info">
    <div class="client-name iq-mb-10">
      <ng-content select="[cardHeader]"></ng-content>
    </div>
    <ng-content select="[cardBody]"></ng-content>
  </div>
</div>

<section class="pb-70" id="iq-blog">
	<div class="container">
		<div class="row">
			<div class="col-lg-12">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
			</div>
			<div class="col-lg-12">
				<app-v-two-posts [postList]="List"></app-v-two-posts>
			</div>
		</div>
	</div>
</section>
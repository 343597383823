<!-- Loader component-->
<app-loader></app-loader>
<!-- Header component start-->
<app-header-style1 [logoImg]="navLogo" className="header-white p-0" [navItemList]="navItems">
</app-header-style1>
<!-- Header component end-->
<!-- Home section start-->
<app-home></app-home>
<!-- Home section end-->
<!-- Main Content -->
<div class="main-content">
  <!-- About section start-->
  <app-about></app-about>
  <!-- About section end-->
  <!-- Service section start-->
  <app-service></app-service>
  <!-- Service section end-->
  <!-- Features section start-->
  <app-features></app-features>
  <!-- Features section end-->
  <!-- Blog section start-->
  <app-blog></app-blog>
  <!-- Blog section end-->
  <!-- About me section start-->
  <app-about-me></app-about-me>
  <!-- About me section end-->
  <!-- Client section start-->
  <app-clients></app-clients>
  <!-- Client section end-->
  <!-- Contact section start-->
  <app-contact></app-contact>
  <!-- Contact section end-->
</div>
<!-- Footer component start-->
<app-footer-style1 [footerText]="footerText" [socialNavItemList]="socialNavItems"></app-footer-style1>
<!-- Footer component end-->
<!-- Scroll top component start-->
<app-scroll-top></app-scroll-top>
<!-- Scroll top component end-->
<!-- Color customizer component start-->
<app-color-customizer></app-color-customizer>
<!-- Color customizer component end-->

<!-- Pricing  -->
<section class="overview-block-ptb iq-pricing-main" id="pricing">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-md-12">
            <div class="heading-title text-center">
               <h3 class="title iq-tw-6">Our Pricing</h3>
               <p>Lorem Ipsum is simply dummy text ever sincehar the 1500s.</p>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-lg-3 col-md-6 col-sm-12 iq-mtb-10" *ngFor="let package of packageList">
            <app-pricing-card [item]="package" [active]="package.active">
            <ul class="iq-mtb-30" cardBody>
               <li class="iq-mtb-20" *ngFor="let item of package.items"><i aria-hidden="true" class="iq-mr-10" [ngClass]="item.icon" ></i>{{ item.title }}</li>
            </ul>
            <a cardFooter class="button iq-mr-0" href="#">Purchase</a>
            </app-pricing-card>
         </div>
      </div>
   </div>
</section>
<!-- Pricing End -->

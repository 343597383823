<section [id]="ids"  class="how-works overview-block-ptb">
  <div class="container">
    <div class="row flex-row-reverse">
      <ng-content select="[cardMedia]"></ng-content>
      <ng-content select="[cardBody]"></ng-content>
      <div class="iq-objectsnew">
      <span class="iq-objects-02" data-bottom="transform:translatey(50px)" data-top="transform:translatey(-100px);">
        <img src="./assets/images/drive/03.png" class="img-fluid" alt="drive02">
      </span>
        <span class="iq-objects-03" data-bottom="transform:translatex(50px)" data-top="transform:translatex(-100px);">
        <img src="./assets/images/drive/04.png" class="img-fluid" alt="drive02">
      </span>
      <span class="iq-objects-04 iq-fadebounce">
        <span class="iq-round"></span>
      </span>
      </div>
    </div>
  </div>
</section>

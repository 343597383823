<!--Feature-->
<section id="great-features" class="life-work-1 overview-block-ptb software">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <img class="img-fluid" src="./assets/images/drive/16.jpg" alt="drive05">
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="heading-title left text-left mt-md-3">
          <h3 class="iq-tw-7 iq-mb-25 title">Sofbox Service</h3>
        </div>
        <p class="iq-mb-25">Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
        <ul class="iq-list">
          <li class="iq-tw-6"><i class="ion-android-done-all iq-mr-10 iq-font-blue iq-font-30"></i><span class="iq-font-black">Simply dummy text of the Lorem Ipsum is printing and setting industry.</span></li>
          <li class="iq-tw-6"><i class="ion-android-done-all iq-mr-10 iq-font-blue iq-font-30"></i><span class="iq-font-black">Simply dummy text of the Lorem Ipsum is printing and setting industry.</span></li>
          <li class="iq-tw-6"><i class="ion-android-done-all iq-mr-10 iq-font-blue iq-font-30"></i><span class="iq-font-black">Simply dummy text of the Lorem Ipsum is printing and setting industry.</span></li>
          <li class="iq-tw-6"><i class="ion-android-done-all iq-mr-10 iq-font-blue iq-font-30"></i><span class="iq-font-black">Simply dummy text of the Lorem Ipsum is printing and setting industry.</span></li>
          <li class="iq-tw-6"><i class="ion-android-done-all iq-mr-10 iq-font-blue iq-font-30"></i><span class="iq-font-black">Simply dummy text of the Lorem Ipsum is printing and setting industry.</span></li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!--Feature end-->

<p class="rs-p-wp-fix"></p>
    <rs-module-wrap id="rev_slider_5_1_wrapper "  data-alias="sofbox-5" data-source="gallery" style="background:transparent;padding:0;margin:0px auto;margin-top:0;margin-bottom:0;">
		<rs-module id="rev_slider_5_1" style="display:none;" data-version="6.1.3">
		<rs-slides>
		<rs-slide data-key="rs-3" id="iq-home" data-title="Slide" data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;">
		<img src="./assets/sofbox-v2/images/transparent.png" class="rev-slidebg" data-no-retina>
		<!--
		--><rs-layer
            id="slider-3-slide-3-layer-0"
            class="now-font"
            data-type="text"
            data-color="#1e1e1e"
            data-rsp_ch="on"
            data-xy="x:c;y:m;yo:-97px,-97px,-60px,-86px;"
            data-text="w:normal;s:45,45,40,26;l:60,60,50,36;a:center;"
            data-frame_0="y:100%;"
            data-frame_0_mask="u:t;"
            data-frame_1="st:920;sp:1200;sR:920;"
            data-frame_1_mask="u:t;"
            data-frame_999="o:0;st:w;sR:6880;"
            style="z-index:10;font-family:Poppins;"
		>Make the web do what you want
		</rs-layer><!--
		--><rs-layer
            id="slider-3-slide-3-layer-2"
            class="now-font"
            data-type="text"
            data-color="#377dff"
            data-rsp_ch="on"
            data-xy="x:c;y:m;yo:-34px,-34px,-13px,-50px;"
            data-text="w:normal;s:45,45,40,24;l:60,60,50,30;a:center;"
            data-frame_0="y:100%;"
            data-frame_0_mask="u:t;"
            data-frame_1="st:900;sp:1200;sR:900;"
            data-frame_1_mask="u:t;"
            data-frame_999="o:0;st:w;sR:6900;"
            style="z-index:9;font-family:Poppins;"
		>Sofbox
		</rs-layer><!--
		--><rs-layer
            id="slider-3-slide-3-layer-3"
            data-type="text"
            data-color="#8e989f"
            data-rsp_ch="on"
            data-xy="x:c;y:m;yo:43px,43px,62px,5px;"
            data-text="w:normal;s:16,16,18,12;l:28,28,30,26;a:center;"
            data-frame_0="y:100%;"
            data-frame_0_mask="u:t;"
            data-frame_1="st:1480;sp:1200;sR:1480;"
            data-frame_1_mask="u:t;"
            data-frame_999="o:0;st:w;sR:6320;"
            style="z-index:8;font-family:var(--body-font-family);"
		>There are many variations of passages of Lorem Ipsum available, but the<br> majority have suffered alteration in some form,
		</rs-layer><!--
		--><rs-layer
            id="slider-3-slide-3-layer-4"
            class="rev-btn"
            data-type="button"
            data-rsp_ch="on"
            data-xy="x:c;y:m;yo:153px,153px,145px,79px;"
            data-text="w:normal;s:18,18,16,16;l:40,40,25,15;"
            data-dim="minh:0px,0px,none,none;"
            data-padding="t:5,5,10,15;r:30,30,20,20;b:5,5,10,15;l:30,30,20,20;"
            data-border="bor:4px,4px,4px,4px;"
            data-frame_0="y:100%;"
            data-frame_0_mask="u:t;"
            data-frame_1="st:2100;sp:1200;sR:2100;"
            data-frame_1_mask="u:t;"
            data-frame_999="o:0;st:w;sR:5700;"
            data-frame_hover="bgc:#1e1e1e;bor:4px,4px,4px,4px;bri:120%;"
            style="z-index:11;background-color:#377dff;font-family:var(--body-font-family);"
		>Subscribe
		</rs-layer><!--
		-->						</rs-slide>
		</rs-slides>
		<rs-progress class="rs-bottom" style="visibility: hidden !important;"></rs-progress>
		</rs-module>
		<script>
            if(typeof revslider_showDoubleJqueryError === "undefined") {
                function revslider_showDoubleJqueryError(sliderID) {
                    var err = "<div class='rs_error_message_box'>";
                        err += "<div class='rs_error_message_oops'>Oops...</div>";
                        err += "<div class='rs_error_message_content'>";
                        err += "You have some jquery.js library include that comes after the Slider Revolution files js inclusion.<br>";
                        err += "To fix this, you can:<br>&nbsp;&nbsp;&nbsp; 1. Set 'Module General Options' ->  'jQuery & OutPut Filters' -> 'Put JS to Body' to on";
                        err += "<br>&nbsp;&nbsp;&nbsp; 2. Find the double jQuery.js inclusion and remove it";
                        err += "</div>";
                        err += "</div>";
                    jQuery(sliderID).show().html(err);
                }
            }
		</script>
</rs-module-wrap>
<app-footer-main>
   <div footerContent>
      <div class="row">
         <div class="col-lg-3 col-md-6 col-sm-6 iq-mtb-20">
            <app-footer-logo
               sectionImage="{{ navLogo }}"
               sectionText="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
               ></app-footer-logo>
         </div>
         <div class="col-lg-3 col-md-6 col-sm-6 iq-mtb-20">
            <app-footer-address [addressContent]="footerText"></app-footer-address>
         </div>
         <div class="col-lg-3 col-md-6 col-sm-6 iq-mtb-20">
            <app-footer-links title="Menu" [content]="footerHome"></app-footer-links>
         </div>
         <div class="col-lg-3 col-md-6 col-sm-6 iq-mtb-20">
            <app-footer-links title="Help" [content]="footerHelp"></app-footer-links>
         </div>
      </div>
      <hr>
      <div class="row iq-mt-20 align-items-center">
         <div class="col-lg-8 col-md-7 col-sm-12 text-left iq-font-dark">
            <div class="" [innerHTML]="footerTextBottom">© 2018 Sofbox Developed by <b>iqonicthemes</b>.</div>
         </div>
         <div class="col-md-5 col-lg-4 col-sm-12 align-self-center">
            <ul class="info-share text-center text-sm-right">
               <li *ngFor="let option of socialNavItems">
                  <a href="{{ option.href }}" [innerHTML]="option.icon"></a>
               </li>
            </ul>
         </div>
      </div>
   </div>
</app-footer-main>

<section class="pb-0" id="iq-feature">
    <div class="container">
        <div class="row justify-content-center position-relative">
            <div class="col-lg-8">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
            </div>
            <div *ngFor="let list of List" class="col-lg-6">
                <div class="color-palette wow fadeInUp text-left {{ list.hoverClass }}">
                    <div class="media">
                        <div class="circle-icon">
                            <i aria-hidden="true" class="{{ list.icon }}"></i>
                        </div>
                        <div class="media-body">
                            <h5 class="color-text mb-2">{{ list.title }}</h5>
                            <p class="mb-0">{{ list.desc }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="iq-objects  style-two left-top"><span class="iq-objects-02">
                <img src="./assets/sofbox-v2/images/home-7/704.png" alt="drive02">
                </span>
            </div>
        </div>
    </div>
</section>
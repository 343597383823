<!-- Banner -->
<app-parallax-hero-style1
  ids="iq-home"
  classNames="iq-banner-07 hero-section overview-block-pt iq-bg-over iq-over-black-10 iq-parallax"
  bgImage="./assets/images/bg/10.jpg"
>
  <div class="container">
    <div class="banner-text">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <h1 class="text-uppercase iq-font-dark iq-tw-3">Provides To Make Better <b class="iq-tw-6">software</b></h1>
          <p class="iq-pt-15 iq-mb-15">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
          <a href="javascript:void(0)" class="button">Download</a>
        </div>
        <div class="col-lg-6 col-md-12">
        </div>
      </div>
    </div>
  </div>
</app-parallax-hero-style1>
<!-- Banner End -->

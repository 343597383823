<div class="blog-title-img text-center  ">
  <ng-content select="[cardMedia]"></ng-content>
  <ng-content select="[cardHeader]"></ng-content>
  <ng-content select="[cardBody]"></ng-content>
  <ul class="social-links">
    <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
    <li><a href="#"><i class="fab fa-twitter"></i></a></li>
    <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
    <li><a href="#"><i class="fab fa-dribbble"></i></a><li>
  </ul>
</div>

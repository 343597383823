<section class="{{ sectionProp.class }}" id="{{ sectionProp.id }}">
	<div class="container">
		<div class="row align-items-center test-shadow">
			<div class="col-lg-6">
                <ng-content select="[imageList1]"></ng-content>
                <ng-content select="[testimonialTitle]"></ng-content>
			</div>
			<div class="col-lg-6">
                <ng-content select="[imageList2]"></ng-content>

				<ng-content select="[testimonialList]"></ng-content>
				
				<div class="layer_wrap iq-objects style-two right-top">
                    <span class="iq-objects-02">
					    <img src="./assets/sofbox-v2/images/home-1/29.png" alt="drive02">
                    </span>
                </div>
			</div>
		</div>
	</div>
</section>
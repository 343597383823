<p class="rs-p-wp-fix"></p>
        <rs-module-wrap id="rev_slider_7_1_wrapper" data-alias="sofbox-7" data-source="gallery" style="background:transparent;padding:0;margin:0px auto;margin-top:0;margin-bottom:0;">
            <rs-module id="rev_slider_7_1" style="display:none;" data-version="6.1.3">
                <rs-slides>
                    <rs-slide data-key="rs-3" id="iq-home" data-title="Slide" data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;">
                        <img src="./assets/sofbox-v2/revslider/assets/index-7/transparent.png" class="rev-slidebg" data-no-retina>
                        <!--
                            -->
                        <rs-layer
                            id="slider-3-slide-3-layer-1"
                            data-type="image"
                            data-rsp_ch="on"
                            data-xy="xo:147px,147px,-9px,-11px;yo:-480px,-480px,-104px,-47px;"
                            data-text="w:normal;s:20,20,9,5;l:0,0,12,7;"
                            data-dim="w:1681px,1681px,815px,502px;h:1205px,1205px,412px,254px;"
                            data-frame_0="sX:0.8;sY:0.8;"
                            data-frame_1="e:Power4.easeOut;sp:1000;"
                            data-frame_999="o:0;st:w;sR:8000;"
                            style="z-index:8;font-family:Roboto;"
                            >
                            <img src="./assets/sofbox-v2/revslider/assets/index-7/bg.png" alt="img" width="1511" height="1083" data-no-retina>
                        </rs-layer>
                        <!--
                            -->
                        <rs-layer
                            id="slider-3-slide-3-layer-2"
                            data-type="image"
                            data-rsp_ch="on"
                            data-xy="x:l,l,c,c;xo:726px,726px,0,3px;y:t,t,t,m;yo:113px,113px,335px,147px;"
                            data-text="w:normal;s:20,20,9,5;l:0,0,12,7;"
                            data-dim="w:869px,869px,297px,298px;h:597px,597px,204px,205px;"
                            data-frame_0="y:100%;"
                            data-frame_0_mask="u:t;"
                            data-frame_1="st:2070;sp:1200;sR:2070;"
                            data-frame_1_mask="u:t;"
                            data-frame_999="o:0;st:w;sR:5730;"
                            style="z-index:9;font-family:Roboto;"
                            ><img src="./assets/sofbox-v2/revslider/assets/index-7/02-min.png" alt="img" width="907" height="623" data-no-retina>
                        </rs-layer>
                        <!--
                            -->
                        <rs-layer
                            id="slider-3-slide-3-layer-7"
                            data-type="text"
                            data-color="#7173f0"
                            data-rsp_ch="on"
                            data-xy="xo:49px,49px,-82px,-76px;yo:261px,261px,182px,113px;"
                            data-text="w:normal;s:16,16,20,12;l:45,45,24,14;a:center;"
                            data-dim="w:45px,45px,21px,12px;h:45px,45px,21px,12px;"
                            data-padding="t:0,0,10,6;r:0,0,10,6;b:0,0,10,6;l:0,0,10,6;"
                            data-border="bor:90px,90px,90px,90px;"
                            data-frame_0="sX:0.9;sY:0.9;"
                            data-frame_1="st:3030;sp:1000;sR:3030;"
                            data-frame_999="o:0;st:w;sR:4970;"
                            data-frame_hover="c:#fff;bgc:#7173f0;bor:90px,90px,90px,90px;"
                            style="z-index:12;background-color:#ebf2ff;font-family:Roboto;cursor:pointer;"
                            ><i class="fa fa-facebook" aria-hidden="true"></i>
                        </rs-layer>
                        <!--
                            -->
                        <rs-layer
                            id="slider-3-slide-3-layer-8"
                            data-type="text"
                            data-color="#7173f0"
                            data-rsp_ch="on"
                            data-xy="xo:49px,49px,-115px,-80px;yo:322px,322px,241px,148px;"
                            data-text="w:normal;s:16,16,20,12;l:45,45,24,14;a: center;"
                            data-dim="w:45px,45px,21px,12px;h:45px,45px,21px,12px;"
                            data-padding="t:0,0,10,6;r:0,0,10,6;b:0,0,10,6;l:0,0,10,6;"
                            data-border="bor:90px,90px,90px,90px;"
                            data-frame_0="sX:0.9;sY:0.9;"
                            data-frame_1="st:3010;sp:1000;sR:3010;"
                            data-frame_999="o:0;st:w;sR:4990;"
                            data-frame_hover="c:#fff;bgc:#7173f0;bor:90px,90px,90px,90px;"
                            style="z-index:10;background-color:#ebf2ff;font-family:Roboto;cursor:pointer;"
                            ><i class="fa fa-linkedin" aria-hidden="true"></i>
                        </rs-layer>
                        <!--
                            -->
                        <rs-layer
                            id="slider-3-slide-3-layer-9"
                            data-type="text"
                            data-color="#7173f0"
                            data-rsp_ch="on"
                            data-xy="xo:49px,49px,-89px,-68px;yo:199px,199px,116px,72px;"
                            data-text="w:normal;s:16,16,20,12;l:45,45,24,14;a:center;"
                            data-dim="w:45px,45px,21px,12px;h:45px,45px,21px,12px;"
                            data-padding="t:0,0,10,6;r:0,0,10,6;b:0,0,10,6;l:0,0,10,6;"
                            data-border="bor:90px,90px,90px,90px;"
                            data-frame_0="sX:0.9;sY:0.9;"
                            data-frame_1="st:3030;sp:1000;sR:3030;"
                            data-frame_999="o:0;st:w;sR:4970;"
                            data-frame_hover="c:#fff;bgc:#7173f0;bor:90px,90px,90px,90px;"
                            style="z-index:11;background-color:#ebf2ff;font-family:Roboto;cursor:pointer;"
                            ><i class="fa fa-twitter" aria-hidden="true"></i>
                        </rs-layer>
                        <!--
                            -->
                        <rs-layer
                            id="slider-3-slide-3-layer-10"
                            data-type="text"
                            data-color="#1e1e1e"
                            data-rsp_ch="on"
                            data-xy="xo:15px,15px,-96px,-100px;yo:424px,424px,341px,215px;"
                            data-text="w:normal;s:16,16,16,9;l:25,25,24,14;"
                            data-btrans="rZ:-90;"
                            data-frame_0="sX:0.9;sY:0.9;"
                            data-frame_1="st:3060;sp:1000;sR:3060;"
                            data-frame_999="o:0;st:w;sR:4940;"
                            style="z-index:13;font-family:var(--body-font-family);"
                            >Follow us on:
                        </rs-layer>
                        <!--
                            -->
                        <rs-layer
                            id="slider-3-slide-3-layer-11"
                            class="now-font"
                            data-type="text"
                            data-color="#1e1e1e"
                            data-rsp_ch="on"
                            data-xy="x:l,l,c,c;xo:170px,170px,-7px,-4px;y:m;yo:-90px,-90px,-153px,-144px;"
                            data-text="w:normal;s:50,50,30,24;l:80,80,44,34;a:left,left,center,center;"
                            data-frame_0="y:100%;"
                            data-frame_0_mask="u:t;"
                            data-frame_1="st:750;sp:1200;sR:750;"
                            data-frame_1_mask="u:t;"
                            data-frame_999="o:0;st:w;sR:7050;"
                            style="z-index:14;font-family:Poppins;"
                            >The biggest platform<br />
                            for your growth.
                        </rs-layer>
                        <!--
                            -->
                        <rs-layer
                            id="slider-3-slide-3-layer-12"
                            data-type="text"
                            data-color="#8e989f"
                            data-rsp_ch="on"
                            data-xy="x:l,l,c,c;xo:170px,170px,9px,8px;y:m;yo:39px,39px,-62px,-67px;"
                            data-text="w:normal;s:16,16,18,14;l:28,28,28,24;a:left,left,center,center;"
                            data-frame_0="y:100%;"
                            data-frame_0_mask="u:t;"
                            data-frame_1="st:980;sp:1200;sR:980;"
                            data-frame_1_mask="u:t;"
                            data-frame_999="o:0;st:w;sR:6820;"
                            style="z-index:15;font-family: var(--body-font-family);"
                            >There are many variations of passages of Lorem Ipsum available, <br> but the majority have suffered alteration in some form,
                        </rs-layer>
                        <!--
                            -->
                        <rs-layer
                            id="slider-3-slide-3-layer-13"
                            class="rev-btn"
                            data-type="button"
                            data-rsp_ch="on"
                            data-xy="x:l,l,c,c;xo:173px,173px,0,1px;y:t,t,m,m;yo:462px,462px,4px,-2px;"
                            data-text="w:normal;s:16,16,14,14;l:40,40,18,26;"
                            data-dim="minh:0px,0px,none,none;"
                            data-padding="t:4,4,12,8;r:25;b:4,4,12,8;l:25;"
                            data-border="bor:4px,4px,4px,4px;"
                            data-frame_0="y:100%;"
                            data-frame_0_mask="u:t;"
                            data-frame_1="st:1320;sp:1200;sR:1320;"
                            data-frame_1_mask="u:t;"
                            data-frame_999="o:0;st:w;sR:6480;"
                            data-frame_hover="bgc:linear-gradient(90deg, rgba(163,120,252,1) 0%, rgba(109,115,239,1) 100%);bor:4px,4px,4px,4px;bri:120%;"
                            style="z-index:16;background:linear-gradient(90deg, rgba(109,115,239,1) 0%, rgba(163,120,252,1) 100%);font-family: var(--body-font-family);"
                            >Read More
                        </rs-layer>
                        <!--
                            -->
                        <rs-layer
                            id="slider-3-slide-3-layer-14"
                            data-type="image"
                            data-rsp_ch="on"
                            data-xy="xo:664px,664px,127px,78px;yo:103px,103px,312px,232px;"
                            data-text="w:normal;s:20,20,9,5;l:0,0,12,7;"
                            data-dim="w:15px,15px,12px,10px;h:15px,15px,12px,10px;"
                            data-frame_0="sX:0.9;sY:0.9;"
                            data-frame_1="st:2400;sp:1000;sR:2400;"
                            data-frame_999="o:0;st:w;sR:5600;"
                            data-loop_0="y:20px;"
                            data-loop_999="y:-20px;sp:3000;e:Sine.easeInOut;yym:t;"
                            style="z-index:17;font-family:Roboto;"
                            ><img src="./assets/sofbox-v2/revslider/assets/index-7/17-1.png" alt="img" width="15" height="15" data-no-retina>
                        </rs-layer>
                        <!--
                            -->
                        <rs-layer
                            id="slider-3-slide-3-layer-15"
                            data-type="image"
                            data-rsp_ch="on"
                            data-xy="x:l,l,r,r;xo:500px,500px,40px,24px;yo:449px,449px,218px,134px;"
                            data-text="w:normal;s:20,20,9,5;l:0,0,12,7;"
                            data-dim="w:12px,12px,10px,6px;h:12px,12px,10px,6px;"
                            data-frame_0="sX:0.9;sY:0.9;"
                            data-frame_1="st:2230;sp:1000;sR:2230;"
                            data-frame_999="o:0;st:w;sR:5770;"
                            data-loop_0="o:0;"
                            data-loop_999="sp:1500;e:Sine.easeInOut;yyf:t;"
                            style="z-index:18;font-family:Roboto;"
                            ><img src="./assets/sofbox-v2/revslider/assets/index-7/16-1.png" alt="img" width="20" height="20" data-no-retina>
                        </rs-layer>
                        <!--
                            -->
                        <rs-layer
                            id="slider-3-slide-3-layer-16"
                            data-type="image"
                            data-rsp_ch="on"
                            data-xy="xo:842px,842px,408px,251px;yo:654px,654px,317px,195px;"
                            data-text="w:normal;s:20,20,9,5;l:0,0,12,7;"
                            data-dim="w:11px,11px,5px,3px;h:11px,11px,5px,3px;"
                            data-frame_0="sX:0.9;sY:0.9;"
                            data-frame_1="st:1950;sp:1000;sR:1950;"
                            data-frame_999="o:0;st:w;sR:6050;"
                            data-loop_0="xR:10px;yR:-10px;"
                            data-loop_999="xR:10px;yR:-10px;crd:t;sp:3000;"
                            style="z-index:20;font-family:Roboto;"
                            ><img src="./assets/sofbox-v2/revslider/assets/index-7/10-2.png" alt="img" width="11" height="11" data-no-retina>
                        </rs-layer>
                        <!--
                            -->
                        <rs-layer
                            id="slider-3-slide-3-layer-17"
                            data-type="image"
                            data-rsp_ch="on"
                            data-xy="xo:116px,116px,56px,34px;yo:112px,112px,53px,32px;"
                            data-text="w:normal;s:20,20,9,5;l:0,0,12,7;"
                            data-dim="w:11px,11px,11px,6px;h:11px,11px,11px,6px;"
                            data-frame_0="sX:0.9;sY:0.9;"
                            data-frame_1="st:2090;sp:1000;sR:2090;"
                            data-frame_999="o:0;st:w;sR:5910;"
                            data-loop_0="y:10px;"
                            data-loop_999="y:-10px;sp:3000;e:Sine.easeInOut;yym:t;"
                            style="z-index:19;font-family:Roboto;"
                            ><img src="./assets/sofbox-v2/revslider/assets/index-7/10-2.png" alt="img" width="11" height="11" data-no-retina>
                        </rs-layer>
                        <!--
                            -->						
                    </rs-slide>
                </rs-slides>
                <rs-progress class="rs-bottom" style="visibility: hidden !important;"></rs-progress>
            </rs-module>
            <script type="text/javascript">
                if(typeof revslider_showDoubleJqueryError === "undefined") {
                	function revslider_showDoubleJqueryError(sliderID) {
                		var err = "<div class='rs_error_message_box'>";
                			err += "<div class='rs_error_message_oops'>Oops...</div>";
                			err += "<div class='rs_error_message_content'>";
                            err += "You have some jquery.js library include that comes after the Slider Revolution files js inclusion.<br>";
                            err += "To fix this, you can:<br>&nbsp;&nbsp;&nbsp; 1. Set 'Module General Options' ->  'jQuery & OutPut Filters' -> 'Put JS to Body' to on";
                            err += "<br>&nbsp;&nbsp;&nbsp; 2. Find the double jQuery.js inclusion and remove it";
                			err += "</div>";
                	        err += "</div>";
                		jQuery(sliderID).show().html(err);
                	}
                }
            </script>
        </rs-module-wrap>